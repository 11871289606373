<template>
    <v-card class="ma-4">
        <v-card-title>
            {{ title }}

            <v-divider
                    class="mx-6"
                    inset
                    vertical
            ></v-divider>


            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    filled
                    hide-details
                    label="Search about something"
                    single-line
            ></v-text-field>

            <v-dialog v-model="dialogAddItem" max-width="750px">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="mx-11"
                            color="red"
                            dark
                            v-bind="attrs"
                            v-on="on"
                    >New
                    </v-btn>
                </template>

                <v-card v-if="dialogAddItem">
                    <v-toolbar
                            color="blue-grey"
                            dark
                    >
                        <v-btn
                                dark
                                icon
                                @click="dialogAddItem = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                    :disabled="(!valid || buttonLoading)"
                                    :loading="buttonLoading"
                                    dark
                                    text
                                    @click="validate"
                            >Save
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>


                    <v-alert
                            v-if="Object.keys(errors).length>0"
                            dense
                            text
                            type="error"
                    >
                        <h4 class="subtitle">Correct the following errors:</h4>
                        <ul>
                            <li
                                    v-for="(error, index) in errors"
                                    :key="index"
                            >{{ error.message || error }}
                            </li>
                        </ul>
                    </v-alert>


                    <v-card-text>
                        <v-container>

                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    :disabled="buttonLoading"
                                    lazy-validation
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="name" :counter="150" :rules="nameRules" clearable label="Skill name"
                                                      outlined required></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-select
                                                v-model="itemSkill"
                                                :items="itemSkills"
                                                :rules="[v => !!v || 'Please select root of skill']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Select from Menu"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6">
                                        <v-checkbox
                                                v-model="isActive"
                                                color="red"
                                                label="show skill in the list skills"
                                        ></v-checkbox>
                                    </v-col>


                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                </v-card>
            </v-dialog>

        </v-card-title>


        <v-data-table
                :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                :loading="loading"
                :options.sync="options"
                :search="search"
                class="elevation-1"
        >


            <template v-slot:item.actions="{ item }">
                <v-icon
                        class="mr-3"
                        color="blue"
                        small
                        @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        color="red"
                        small
                        @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>


            <template v-slot:item.isActive="{ item }">
                <v-icon :color="(item.isActive)?'green':'red'">
                    mdi-eye
                </v-icon>
            </template>

            <template v-slot:item.parentID="{ item }">
                <v-chip color="orange" dark>{{ getParentSkill(item) }}</v-chip>
            </template>

            <template v-slot:item.name="{ item }">
                <v-chip dark>{{ item.name }}</v-chip>
            </template>

        </v-data-table>
    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {urlCreateSkill, urlSkills, urlUpdateSkill} from "@/config";

export default {
    data: () => ({
        title: "Engineers Skills",


        id: 0,
        name: null,
        nameRules: [
            v => !!v || 'name is required',
            v => (v && v.length <= 150) || 'name must be less than 150 characters',
        ],

        isActive: true,
        itemSkill: null,
        itemSkills: [{name: "Main", id: 0}],

        valid: true,
        search: '',
        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        dialogAddItem: false,
        options: null,
        desserts: [],
        loading: true,

        headers: [
            {text: 'Name', value: 'name', align: 'center'},
            {text: 'Root', value: 'parentID', align: 'center'},
            {text: 'Status', value: 'isActive', align: 'center'},
            {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
        ],
        isFormTitleEdit: false,


    }),

    created() {
        this.getSkillsFromApi();
    },


    computed: {
        formTitle() {
            return this.isFormTitleEdit ? 'Edit skill' : 'Add new skill';
        },
    },

    watch: {


        dialogAddItem() {
            if (!this.dialogAddItem) {
                this.isFormTitleEdit = false;
                this.clear();
            }

        },


    },

    methods: {
        getSkillsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlSkills)
                .then(response => {

                    this.desserts = response.data;
                    this.itemSkills =
                        this.desserts.filter(c => c.parentID === 0).concat(this.itemSkills);

                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },


        getParentSkill(item) {

            if (item.parentID == 0)
                return "Main";
            else {
                let parent = this.desserts.filter(c => c.id == item.parentID);
                return parent[0].name;
            }

        },

        validate() {

            if (this.$refs.form.validate()) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {
            let data = {
                name: this.name,
                isActive: this.isActive,
                parentID: this.itemSkill.id,

            };


            if (this.isFormTitleEdit)
                networks.fetchFromWeb(urlUpdateSkill + this.id, 2, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been edited successfully");
                            this.getSkillsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {

                networks.fetchFromWeb(urlCreateSkill, 1, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been added successfully");
                            this.getSkillsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }

        },

        clear() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            this.name = '';
            this.id = 0;
            this.itemSkill = null;
            this.isActive = true;

            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


        editItem(item) {

            this.clear();
            this.isFormTitleEdit = true;
            this.id = item.id;
            this.name = item.name;
            this.isActive = item.isActive;
            this.itemSkill = {id: item.parentID};
            this.dialogAddItem = true;
        },

        deleteItem(item) {
            const index = this.desserts.indexOf(item);
            confirm('do you want to delete this ?') && this.desserts.splice(index, 1)
        },


    },
}
</script>



